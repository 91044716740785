import { TypePolicy } from '@apollo/client';

const types = [
  'Booking',
  'Appointment',
  'CustomerInput',
  'ServiceOffering',
  'ServiceArea',
];

const idTypes = [
  'EventOffering',
  'Location',
  'Customer',
  'CustomerProfile',
  'CustomerProfileField',
];

const typePolicies: Record<string, TypePolicy> = {};

const typePoliciesWithUid = types.reduce((acc, type) => {
  acc[type] = {
    keyFields: ['uid'],
  };
  return acc;
}, typePolicies);

const typePoliciesWithId = idTypes.reduce((acc, type) => {
  acc[type] = {
    keyFields: ['id'],
  };
  return acc;
}, typePolicies);

export const peazyApolloTypePolicies = {
  ...typePoliciesWithUid,
  ...typePoliciesWithId,
};

