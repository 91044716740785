'use client';
import { ApolloLink, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { InMemoryCache, ApolloNextAppProvider, ApolloClient, SSRMultipartLink } from '@apollo/experimental-nextjs-app-support';
import { createUploadLink } from 'apollo-upload-client';
import { createApolloErrorLink, setAppApolloClientContextLink } from 'Common/functions/ApolloClient';
import { peazyApolloTypePolicies } from 'Common/functions/ApolloTypePolicy';

function makeClient() {
  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
    fetchOptions: { cache: 'no-store' },
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_V2_GATEWAY_ENDPOINT,
    credentials: 'include',
    fetchOptions: {
      cache: 'no-store',
    },
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error && error.statusCode !== 400 && error.statusCode !== 500
    },
    delay: {
      initial: 300,
      max: 3000,
      jitter: true
    },
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        ...peazyApolloTypePolicies,
      }
    }),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
          new SSRMultipartLink({
            stripDefer: true,
          }),
          retryLink,
          createApolloErrorLink().concat(httpLink),
        ])
        : ApolloLink.from([
          createApolloErrorLink(),
          retryLink,
          setAppApolloClientContextLink,
          uploadLink
        ]),
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
